import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "../../../actionCreators/ajaxActionCreator";
import { PartialFailureBusinessError } from "../../../actions/actionErrors";
import {
  createDashboardApiCall,
  fetchDashboardsApiCall,
  computeDashboardsApiCall,
  deleteDashboardApiCall,
  updateDashboardApiCall,
  restoreDashboardsApiCall,
  archiveDashboardsApiCall,
} from "./api";
import { createNotificationActionCreator } from "../../notifications/actionCreator";
import * as notificationTypes from "../../notifications/actionTypes";
import * as levels from "../../notifications/constants";
import { getSelectedClient } from "../../clients/redux/selectors";
import {
  createDashboardBeginActionCreator,
  createDashboardFailureActionCreator,
  createDashboardSuccessActionCreator,
  fetchAllDashboardsBeginActionCreator,
  fetchAllDashboardsSuccessActionCreator,
  computeDashboardBeginActionCreator,
  computeDashboardSuccessActionCreator,
  fetchAllDashboardsFailureActionCreator,
  computeDashboardFailureActionCreator,
  deleteDashboardSuccessActionCreator,
  deleteDashboardBeginActionCreator,
  deleteDashboardFailureActionCreator,
  updateDashboardBeginActionCreator,
  updateDashboardSuccessActionCreator,
  updateDashboardFailureActionCreator,
  restoreDashboardsBeginActionCreator,
  restoreDashboardsFailureActionCreator,
  restoreDashboardsSuccessActionCreator,
  archiveDashboardsFailureActionCreator,
  archiveDashboardsSuccessActionCreator,
  archiveDashboardsBeginActionCreator,
  changeSubcategorySuccessActionCreator,
} from "./actionCreators";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "../../../actions/appActions";
import {
  getLang,
  getActionsAvailableSelector,
} from "../../authentication/redux/selector";
import * as lang from "../../../lang";
import { getSuccessNotificationMessage } from "../../../lang/utils";
import { IDashboard, IKPI, KPI_TYPE } from "model/entities/Dashboard";
import { IDispatchAndGetState } from "store/model";
import TLang, { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { IClient } from "model/entities/Client";
import { fetchTeamsForClientAction } from "containers/teams/redux/actions";
import { FILTER_TAG } from "components/Filter/TypeFilter";
import { activeTeamsComposedSelector } from "containers/teams/redux/selectors";

interface KPIWithIID extends IKPI {
  iid?: string;
}

/**
 * Create Dashboard action which creates a dashboard for embedding
 * @param {String} title Title of the dashboard
 * @param {String} type Type of the dashboard (between WEB, MOBILE, WEB_AND_MOBILE)
 * @param {String} description Description of the dashboard
 * @param {Array} charts Charts of dashboards
 * @param {String} clientId Client id
 * @returns {Function}
 */
export const createDashboardAction: ICreateDashboardActionFunc = (
  dashboard: IDashboard,
  clientId = undefined
) => {
  return (dispatch, getState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(createDashboardBeginActionCreator());

    const client = getSelectedClient(getState()) as IClient;

    let client_id = clientId || client.id;

    // add iid for kpis
    const kpis: KPIWithIID[] = dashboard.kpis.map((kpi: KPIWithIID, idx) => {
      // delete kpi.id;
      kpi.iid = "" + idx;
      return kpi;
    });

    dashboard.kpis = kpis;

    return createDashboardApiCall(dashboard, client_id)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { dashboard_id } = data;
        const dashboardWithId = {
          ...dashboard,
          id: dashboard_id,
          kpis: dashboard.kpis.map((kpi: KPIWithIID) => {
            const resFindKpi = data.kpis.find((k) => k.iid === kpi.iid);
            kpi.id = resFindKpi ? resFindKpi.id : "";
            delete kpi.iid;
            return kpi;
          }),
        };
        dispatch(ajaxSuccessAction());
        dispatch(createDashboardSuccessActionCreator(dashboardWithId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.CREATE,
              SUB_CATEGORIES.DASHBOARD
            )
          )
        );
      })

      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "CreateDashboard",
          error,
          createDashboardFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Update Dashboard action which creates a dashboard for embedding
 * @param {Object} dashboard Dashboard object to edit
 * @param {String} clientId Client id
 * @returns {Function}
 */
export const updateDashboardAction: IUpdateDashboardActionFunc = (
  dashboard
) => {
  dashboard.kpis.forEach((kpi) => delete kpi.data);
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(updateDashboardBeginActionCreator());

    const client = getSelectedClient(getState()) as IClient;

    let client_id = client.id;

    // add iid for kpis
    const kpis = dashboard.kpis.map((kpi: KPIWithIID, idx) => {
      // delete kpi.id;
      kpi.iid = "" + idx;
      return kpi;
    });

    return updateDashboardApiCall({ ...dashboard, kpis }, client_id)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const dashboardWithId = {
          ...dashboard,
          kpis: dashboard.kpis.map((kpi: KPIWithIID) => {
            const resFindKpi = data.kpis.find((k) => k.iid === kpi.iid);
            kpi.id = resFindKpi ? resFindKpi.id : "";
            delete kpi.iid;
            return kpi;
          }),
        };
        dispatch(ajaxSuccessAction());
        dispatch(updateDashboardSuccessActionCreator(dashboardWithId));

        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.EDIT,
              SUB_CATEGORIES.DASHBOARD,
              dashboard.title
            )
          )
        );
      })
      .then(() => dispatch(fetchTeamsForClientAction(client_id) as any))
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "UpdateDashboard",
          error,
          updateDashboardFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Action to fetch allDashboards
 * @param {String} clientId [Optional] optional client ids
 * @returns {Function}
 */
export const fetchAllDashboardsAction: IFetchAllDashboardsActionFunc = (
  clientId = undefined
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];

    const client = getSelectedClient(getState()) as IClient;
    const client_id = clientId || client.id;
    dispatch(ajaxRequestAction());
    dispatch(fetchAllDashboardsBeginActionCreator());

    return fetchDashboardsApiCall(client_id)
      .then((serverResponse) => {
        const data = extractDataAndCheckErrorStatus(serverResponse);
        const { dashboards } = data;
        dispatch(ajaxSuccessAction());
        dispatch(fetchAllDashboardsSuccessActionCreator(dashboards));
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "FetchAllDashboardsError",
          error,
          fetchAllDashboardsFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Delete Dashboard Action dispatches action creators to redux store and makes api calls to delete a license by id
 * @param {String} dashboard_id Dashboard id to delete
 * @param {String} dashboard_name Dashboard name to delete
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const deleteDashboardAction: IDeleteDashboardActionFunc = (
  dashboardId,
  dashboardName
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(deleteDashboardBeginActionCreator());

    const client = getSelectedClient(getState()) as IClient;
    const clientId = client.id;

    return deleteDashboardApiCall(dashboardId, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(deleteDashboardSuccessActionCreator(dashboardId));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.DELETE,
              SUB_CATEGORIES.DASHBOARD,
              dashboardName
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "DeleteDashboardError",
          error,
          deleteDashboardFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Archive Dashboards Action dispatches action creators to redux store and makes api calls to archive dashboards
 * @param {String} dashboardIds Dashboard ids to archive
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const archiveDashboardsAction: IArchiveDashboardsActionFunc = (
  dashboardIds
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(archiveDashboardsBeginActionCreator());

    const client = getSelectedClient(getState()) as IClient;
    const clientId = client.id;

    return archiveDashboardsApiCall(dashboardIds, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(archiveDashboardsSuccessActionCreator(dashboardIds));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.ARCHIVE,
              SUB_CATEGORIES.DASHBOARD
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "ArchiveDashboardsError",
          error,
          archiveDashboardsFailureActionCreator,
          currLang
        );
      });
  };
};

/**
 * Restore Dashboards Action dispatches action creators to redux store and makes api calls to restore dashboards
 * @param {String} dashboardIds Dashboard ids to restore
 * @return {Function} Function with dispatch and getState() arguments, with the latter being optional
 * */
export const restoreDashboardsAction: IRestoreDashboardsActionFunc = (
  dashboardIds
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    dispatch(ajaxRequestAction());
    dispatch(restoreDashboardsBeginActionCreator());

    const client = getSelectedClient(getState()) as IClient;
    const clientId = client.id;

    return restoreDashboardsApiCall(dashboardIds, clientId)
      .then((serverResponse) => {
        extractDataAndCheckErrorStatus(serverResponse);
        dispatch(ajaxSuccessAction());
        dispatch(restoreDashboardsSuccessActionCreator(dashboardIds));
        dispatch(
          createNotificationActionCreator(
            notificationTypes.NOTIFICATION_SUCCESS,
            levels.NOTIFICATION_LEVEL_SUCCESS,
            getSuccessNotificationMessage(
              currLang,
              LANG_ACTIONS.RESTORE,
              SUB_CATEGORIES.DASHBOARD
            )
          )
        );
      })
      .catch((error) => {
        treatErrorNotification(
          dispatch,
          "RestoreDashboardsError",
          error,
          restoreDashboardsFailureActionCreator,
          currLang
        );
      });
  };
};

export const computeDashboardAction: IComputeDashboardActionFunc = (
  selectedDashboard,
  query,
  nbTotalOfTeams,
  callback,
  degradedModeKpis
) => {
  return (dispatch, getState) => {
    const currLang = (lang as any as TLang)[getLang(getState())];
    const client = getSelectedClient(getState()) as IClient;
    const actionsAvailable = getActionsAvailableSelector(getState());
    const teams = activeTeamsComposedSelector(getState());
    const client_id = client.id;

    if (
      actionsAvailable.hasOwnProperty("ACCESS_ALL_RESOURCES") &&
      !actionsAvailable["ACCESS_ALL_RESOURCES"]
    ) {
      if (!query[FILTER_TAG.TEAMS] || query[FILTER_TAG.TEAMS].length === 0) {
        query[FILTER_TAG.TEAMS] = teams.map((t) => t.id);
      }
    }

    if (degradedModeKpis) {
      dispatch(ajaxRequestAction());
      return computeDashboardsApiCall(
        client_id,
        selectedDashboard.id,
        query,
        client.meta_hierarchy_dependencies,
        degradedModeKpis
      )
        .then(() => {
          dispatch(ajaxSuccessAction());
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "ComputeDashboardError",
            error,
            computeDashboardFailureActionCreator,
            currLang
          );
        });
    } else {
      dispatch(ajaxRequestAction());
      dispatch(computeDashboardBeginActionCreator());
      return computeDashboardsApiCall(
        client_id,
        selectedDashboard.id,
        query,
        client.meta_hierarchy_dependencies,
        degradedModeKpis
      )
        .then((serverResponse) => {
          const data = extractDataAndCheckErrorStatus(serverResponse);
          let { kpis } = data;
          selectedDashboard.kpis
            .filter(
              (kpi) =>
                kpi.type === KPI_TYPE.PIE_CHART &&
                ((kpi.use_fields && kpi.percentage) ||
                  (!kpi.use_fields && kpi.is_percentage))
            )
            .forEach((kpi) => {
              const k = kpis.find((k) => k.id === kpi.id);
              if (k) {
                let sum = 0;
                (k.data as any[]).forEach((d) => {
                  sum = sum + (d.value || 0);
                });
                (k.data as any[]).forEach(
                  (d: { label: string; value: number }) => {
                    d.label = d.label + " (%)";
                    d.value =
                      Math.round(sum === 0 ? 0 : (d.value * 1000) / sum) / 10;
                  }
                );
              }
            });
          if (selectedDashboard.id === "_log_report") {
            kpis = kpis.map((k) => ({
              ...k,
              data: (k.data as any[]).map((d) => {
                delete d.id;
                return d;
              }),
            }));
          }
          dispatch(ajaxSuccessAction());
          if (
            query[FILTER_TAG.TEAMS] &&
            query[FILTER_TAG.TEAMS].length === nbTotalOfTeams
          ) {
            delete query[FILTER_TAG.TEAMS];
          }
          dispatch(
            computeDashboardSuccessActionCreator(
              kpis.filter(
                (k) =>
                  (k.data && Array.isArray(k.data)) ||
                  !(
                    k.data &&
                    (k.data as { error: any }).error &&
                    (k.data as { error: any }).error !== "timeout"
                  )
              ),
              selectedDashboard.id,
              query
            )
          );
          if (callback) callback();
          kpis.forEach((k) => {
            if (
              k.data &&
              (k.data as { error: any }).error &&
              (k.data as { error: any }).error !== "timeout"
            ) {
              throw new PartialFailureBusinessError(
                "All dashboards could not be loaded",
                (k.data as { error: any }).error
              );
            }
          });
        })
        .catch((error) => {
          treatErrorNotification(
            dispatch,
            "ComputeDashboardError",
            error,
            computeDashboardFailureActionCreator,
            currLang
          );
        });
    }
  };
};

/**
 * This will be used to change the subcategory in selection
 * @param {String} subcategory Can be either "mobileuser" or "webuser"
 * @returns {Function} dispatch function that is used by Redux thunk and the actions are passed to the reducer to
 * update the state of the store
 * */
export const changeSubcategoryAction: IChangeSubcategoryActionFunc = (
  subcategory
) => {
  return (dispatch) => {
    return new Promise(() =>
      dispatch(changeSubcategorySuccessActionCreator(subcategory))
    );
  };
};

export type ICreateDashboardActionFunc = (
  dashboard: IDashboard,
  clientId?: string
) => IDispatchAndGetState<void>;
export type IUpdateDashboardActionFunc = (
  dashboard: IDashboard
) => IDispatchAndGetState<any>;
export type IFetchAllDashboardsActionFunc = (
  clientId?: string
) => IDispatchAndGetState<void>;
export type IDeleteDashboardActionFunc = (
  dashboardId: string,
  dashboardName: string
) => IDispatchAndGetState<void>;
export type IArchiveDashboardsActionFunc = (
  dashboardIds: string[]
) => IDispatchAndGetState<void>;
export type IRestoreDashboardsActionFunc = (
  dashboardIds: string[]
) => IDispatchAndGetState<void>;
export type IComputeDashboardActionFunc = (
  selectedDashboard: IDashboard,
  query: any,
  nbTotalOfTeams: number,
  callback?: any,
  degradedModeKpis?: string[]
) => IDispatchAndGetState<void>; // FIXME: change the "any" type to a more specific one
export type IChangeSubcategoryActionFunc = (
  subcategory: string
) => IDispatchAndGetState<void>;

export interface IDashboardActions {
  createDashboardAction: ICreateDashboardActionFunc;
  updateDashboardAction: IUpdateDashboardActionFunc;
  fetchAllDashboardsAction: IFetchAllDashboardsActionFunc;
  deleteDashboardAction: IDeleteDashboardActionFunc;
  archiveDashboardsAction: IArchiveDashboardsActionFunc;
  restoreDashboardsAction: IRestoreDashboardsActionFunc;
  computeDashboardAction: IComputeDashboardActionFunc;
  changeSubcategoryAction: IChangeSubcategoryActionFunc;
}

const actions: IDashboardActions = {
  createDashboardAction,
  updateDashboardAction,
  fetchAllDashboardsAction,
  deleteDashboardAction,
  archiveDashboardsAction,
  restoreDashboardsAction,
  computeDashboardAction,
  changeSubcategoryAction,
};

export default actions;
